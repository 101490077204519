import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/gatsby-blog--next-and-previous-links",
  "title": "Gatsby Blog: Next and Previous Links",
  "excerpt": "Adding previous and next links to blog page",
  "tags": ["web development", "gatsby", "react", "javascript", "learning"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/blog-covers/19ytkgnjkp74p5jxyqr9_dqt8eh.png",
    "originalHeight": 1063,
    "originalWidth": 1600,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "draft": false,
  "type": "blog",
  "date": "2019-11-18T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I wanted to add next and previous links to the bottom of my blog posts for a bit. To make this work I started by going into `}<inlineCode parentName="p">{`gatsby-node`}</inlineCode>{` and finding the query that is used to generate my blog posts.`}</p>
    <p>{`This query just gets all the nodes (the markdown files) and get's the html, id and frontmatter in the form of the path name, blog title, blog date and blog tags associated with each markdown file.`}</p>
    <deckgo-highlight-code {...{
      "language": "javascript",
      "terminal": "carbon",
      "theme": "blackboard"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`allMarkdownRemark {
  edges {
    node {
      html
      id
      frontmatter {
        path
        title
        date
        tags
      }
    }
  }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Then I find the section of code that actually generates the blog post page. It's taking that query and looping over each node and then passing it the path and telling it what component to render.`}</p>
    <deckgo-highlight-code {...{
      "language": "javascript",
      "terminal": "carbon",
      "theme": "blackboard"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`res.data.allMarkdownRemark.edges.forEach(({ node }) => {
  createPage({
    path: node.frontmatter.path,
    component: postTemplate,
  });
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The changes that I need to make are getting the next and previous from my query and then passing those into my generated pages. For `}<inlineCode parentName="p">{`allMarkdownRemark`}</inlineCode>{` there is an option for `}<inlineCode parentName="p">{`next`}</inlineCode>{` and `}<inlineCode parentName="p">{`previous`}</inlineCode>{` under `}<inlineCode parentName="p">{`edges`}</inlineCode>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://thepracticaldev.s3.amazonaws.com/i/yff4amb61ljc50gq96pw.png",
        "alt": "next and previous are options under edges"
      }}></img></p>
    <p>{`Adding these options under node (equal to node not inside node) in the query so I can access that information in my createPage function.`}</p>
    <p>{`Changing the query to resemble this:`}</p>
    <deckgo-highlight-code {...{
      "language": "javascript",
      "terminal": "carbon",
      "theme": "blackboard"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`allMarkdownRemark {
  edges {
    node {
      html
      id
      frontmatter {
        path
        title
        date
        tags
      }
    }
    next {
      frontmatter {
        path
        title
      }
    }
    previous {
      frontmatter {
        path
        title
      }
    }
  }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Now we have access to previous and next and their frontmatter information, path and title, in the createPage function.`}</p>
    <p>{`To update the blog post generating with our next and previous, just add them behind node. Updating the code to this:`}</p>
    <deckgo-highlight-code {...{
      "language": "javascript",
      "terminal": "carbon",
      "theme": "blackboard"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`res.data.allMarkdownRemark.edges.forEach(({ node, next, previous }) => {
  createPage({
    path: node.frontmatter.path,
    component: postTemplate,
  });
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Now our createPage has access to next and previous. What we can do now is pass these down to the component by using `}<inlineCode parentName="p">{`context`}</inlineCode>{`. Context will be an object with the information that will be passed to a component. It can then be accessed in a component by using `}<inlineCode parentName="p">{`pageContext`}</inlineCode>{`.`}</p>
    <deckgo-highlight-code {...{
      "language": "javascript",
      "terminal": "carbon",
      "theme": "blackboard"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`res.data.allMarkdownRemark.edges.forEach(({ node, next, previous }) => {
  createPage({
    path: node.frontmatter.path,
    component: postTemplate,
    context: {
      next,
      previous,
    },
  });
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Now if we go into the component and use `}<inlineCode parentName="p">{`pageContext`}</inlineCode>{` we can destructure it to get next and previous.`}</p>
    <deckgo-highlight-code {...{
      "language": "react",
      "terminal": "carbon",
      "theme": "blackboard"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`const BlogTemplate = ({ pageContext }) => {
  const { next, previous } = pageContext;
  const nextArticle = next && (
    <Link to={next.frontmatter.path} style={{ maxWidth: '25%'}}>
      <strong>Next Article</strong> <br/>
      {next.frontmatter.title}
    </Link>
  )

  const prevArticle = previous && (
    <Link to={previous.frontmatter.path} style={{ maxWidth: '25%'}}>
      <strong>Previous Article</strong> <br/>
      {previous.frontmatter.title}
    </Link>
  )
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Great! We've now got access to next and previous and can now see them in our blog posts. There is one issue that I ran into, the next and previous aren't showing the correct order of posts. The next article isn't technically the next article that's showing on the homepage.`}</p>
    <p>{`The solution to this is to sort the query used in `}<inlineCode parentName="p">{`gatsby-node`}</inlineCode>{`. To display my blog posts, on the homepage I'm using a query that is sorting the posts by the frontmatter date and arranging them in descending order.`}</p>
    <deckgo-highlight-code {...{
      "language": "javascript",
      "terminal": "carbon",
      "theme": "blackboard"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`allMarkdownRemark(
  sort: {
    fields: frontmatter___date,
    order: DESC
  }
)`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This should fix the issue, if you notice your next and previous are not matching up with your layout of your articles.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      